/**
 * @name 레이아웃
 */
//============================================================================ Layout
.contents {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  //배경색
  background: #f2f4f9;
}
// 글로벌네비게이션
.gnb {
  display: flex;
  width: 264px;
  // height: 100vh;
  flex: none; //* 원래크기대로유지
  justify-content: flex-start;
}
// 컨테이너영역
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
// top
.top {
  display: flex;
  height: 62px;
  z-index: 10;
  border-bottom: 1px solid var(--primary-blue-90, #e9f0fe);
  background-color: var(--COLOR_WHITE);
  // box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
}
// children
.children {
  // display: block;
  // width: calc(100vw - 260px);
  // padding: 24px;
  min-height: calc(100vh - 62px - 48px);
}
//============================================================================ common

// 메인상단(지역선택,검색기간설정)
.headers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--COLOR_WHITE);
}
// 메인우측영역(랭킹)
.aside {
  display: flex;
  width: 312px;
  // height: var(--DOC_HEIGHT);
  margin-left: 24px;
  @media all and (min-width: 0) and (max-width: 900px) {
    display: none;
  }
}
// 팝업
.layer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  min-width: 100px;
  min-height: 100px;
  border-radius: 12px;
  background-color: var(--COLOR_WHITE);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
}
// 모달처리
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  > main {
    position: absolute;
    top: 45%;
    left: 50%;
    padding: 10px;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    // width: 80%;
    min-width: 300px;
    background-color: #ffffff;
    overflow: 'auto';
  }
}
// 패널(그림자효과)
.panel {
  background-color: var(--COLOR_WHITE);
  border: 1px solid var(--COLOR_GRAY2);
  border-radius: 8px;
  flex-direction: column;
  // -webkit-mask-image: -webkit-radial-gradient(white, black);
  box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
  background: var(--COLOR_WHITE);
}
