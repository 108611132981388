//*--------------------------------------------------* 버튼
.btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  color: #fff;
  border: 0;
  box-sizing: border-box;
  box-shadow: 0 3px 3px rgba(56, 65, 74, 0.4);
  background-color: #151529;
}
// 텍스트
.input {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  border: 0;
  padding: 0 12px;
  border-radius: 8px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  outline: none;
  transition: all 0.2s cubic-bezier(0.8, 0.07, 0.31, 1);
  // background-color: var(--COLOR_GRAY6);
  // &:focus {
  //   border: 1px solid var(--COLOR_BLACK);
  //   background-color: var(--COLOR_GRAY6);
  // }
}
