/**
 * @name root 글로벌변수
 */
:root {
  /*===================================================================[height] */
  --DOC_HEIGHT: calc(100vh);
  --CONTAINER_HEIGHT: calc(100vh - 62px - 48px - 40px); // 컨테이너 높이
  --MAIN_HEIGHT: calc(100vh - 62px - 48px); // 대시보드 우측영역 높이
  --PANEL_HEIGHT: calc(100vh - 62px - 48px - 150px); // 대시보드 우측영역 높이
  /*===================================================================[color] */
  --COLOR_WHITE: white;
  --COLOR_BLACK: #000000;
  /**------------------------------------[GRAY] */
  --COLOR_GRAY1: #5f5f5f;
  --COLOR_GRAY2: #8a8a8a;
  --COLOR_GRAY3: #b2b2b2;
  --COLOR_GRAY4: #d8d8d8;
  --COLOR_GRAY5: #eaeaea;
  --COLOR_GRAY6: #f4f4f4;
  /**------------------------------------[BLUE] */
  --COLOR_BLUE0: #0f3fb7;
  --COLOR_BLUE1: #1146cb;
  --COLOR_BLUE2: #1850dd;
  --COLOR_BLUE3: #2b64f5;
  --COLOR_BLUE4: #4073f4;
  --COLOR_BLUE5: #6690fb;
  --COLOR_BLUE6: #82a5ed;
  --COLOR_BLUE7: #bed0f5;
  --COLOR_BLUE8: #d6e2fb;
  --COLOR_BLUE9: #f7faff;
  // placeholder
  --COLOR_INPUT_PLACEHOLDER: #ccc;
  /*===================================================================[color] */
  --box-shadow: 0 0 0 1px rgba(3, 3, 0, 0.9), 0 1px 2px rgba(0, 0, 0, 0.9);
}

/********************************************************
[사용법]
  border-bottom: 1px solid var(--COLOR_GRAY5);
  min-height: var(--DOC_HEIGHT);
  max-height: var(--DOC_HEIGHT);
*********************************************************/
