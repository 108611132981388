/**
 * @name Text
 * @description
 */
//============================================================================ 기본폰트스타일
.essential-text {
  vertical-align: middle;
  font-family: 'Pretendard';
}
//============================================================================ input,textarea
textarea {
  resize: none; // 리사이즈제거
}
//============================================================================ Text
body {
  .Headline_SB {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0px;
    text-decoration: none;
    font-weight: bold;
  }
  .Headline_MB {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0px;
    text-decoration: none;
    font-weight: bold;
  }
  .Headline_LB {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0px;
    text-decoration: none;
    font-weight: bold;
  }
  //
  .Title_SB {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    text-decoration: none;
    font-weight: bold;
  }
  .Title_MB {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    text-decoration: none;
    font-weight: bold;
  }
  .Title_LB {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0px;
    text-decoration: none;
    font-weight: bold;
  }
  // Body
  .Body_SM {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: 500;
  }
  .Body_SR {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: normal;
  }
  .Body_MM {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: 500;
  }
  .Body_MR {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: normal;
  }
  .Body_MM_underline {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.2px;
    text-decoration: underline;
    font-weight: 500;
  }
  .Body_MR_underline {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.2px;
    text-decoration: underline;
    font-weight: normal;
  }
  .Body_LM {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: 500;
  }
  .Body_LR {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: normal;
  }
  .Caption_SB {
    font-size: 10px;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: bold;
  }
  .Caption_SR {
    font-size: 10px;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: normal;
  }
  .Caption_MB {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: bold;
  }
  .Caption_MR {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: normal;
  }
  .Caption_LB {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: bold;
  }
  .Caption_LR {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.2px;
    text-decoration: none;
    font-weight: normal;
  }
  .Btn_S {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0px;
    text-decoration: none;
    font-weight: normal;
  }
  .Btn_M {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0px;
    text-decoration: none;
    font-weight: 600;
  }
  .Btn_L {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    text-decoration: none;
    font-weight: normal;
  }
}
