/**
 * @name Fonts
 * @description
 */

//*--------------------------------------------------*

//|------------------ 가로스크롤
.ui-horizon-scroll {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
