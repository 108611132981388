/* 포지션위치중앙 */
.cursor {
  cursor: pointer;
}
/* 포지션위치중앙 */
.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 트랜지션 */
.trans {
  transition: all 0.5s cubic-bezier(0.8, 0.07, 0.31, 1);
}
/* none */
.none {
  display: none;
}
/* auto */
.auto {
  margin-left: auto;
  margin-right: auto;
}
// 디버그화면
.debug {
  box-sizing: border-box;
  border: 3px dotted black;
  background: olive;
}
// float 해제속성
.clear:after {
  content: '';
  display: block;
  clear: both;
}
/*===================================================================[Height설정] */
.container-height {
  height: var(--CONTAINER_HEIGHT);
}
.panel-scroll-height {
  height: var(--PANEL_HEIGHT);
}
/*===================================================================[Position설정] */
.w400 {
  width: 400px;
}
/*===================================================================[Position설정] */
.fixed {
  position: fixed;
}
/*===================================================================[Flex설정] */
/* flex기본값 */
.flex {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}
.center {
  display: flex;
  width: 100%;
  justify-content: center;
  align-self: center;
}
.column {
  display: flex;
  flex-direction: column;
}
/* 가로 */
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* 양쪽정렬 */
.space_between {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
/*===================================================================[include] */
header.inc_header {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 19px 24px;
  border-bottom: 1px solid var(--COLOR_GRAY5);
  align-items: center;
  justify-content: space-between;
}
// 폼입력(등록)
main.inc_form {
  width: 400px;
  margin: 24px auto 32px;
}
/*===================================================================[table설정] */
// 리스트테이블
.table_list {
  padding: 16px;
}
/*===================================================================[infoWindow] */
#infoWindow {
  width: 312px;
  min-height: 200px;
  padding: 14px 16px;
  background-color: #fff;
  border-radius: 8px;
  .thumb {
    width: 200px;
    height: auto;
  }
  p {
    display: block;
    margin-bottom: 5px;
    color: #373737;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.18px;
  }
}
.gm-style-iw-d {
  display: block !important;
}
.gm-style .gm-style-iw-d {
  display: block !important;
  overflow: hidden !important;
}
.gm-style .gm-style-iw-c {
  padding: 0;
}
.gm-style-iw-t {
  // padding: 20px;
  .gm-ui-hover-effect {
    display: none !important;
    width: 40px !important;
    height: 40px !important;
  }
}
//*--------------------------------------------------*
